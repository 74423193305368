.businessTop{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.businessCard {
  height: 150px;
  width: 300px;
  background: rgba(35, 83, 240, 0.15);
  border-radius: 16px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  border: 1px solid rgb(7, 18, 168);
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.businessName{
  font-size: 42px;
  margin-left: 20px;
}

.businessText{
    text-align: justify;
    font-size: 18px;
}

.productsHead{
  font-size: 34px;
  text-align: center;
  overflow-x: scroll;
  overflow-y: hidden;
}

.productTable {
  width: 100%;
  border-collapse: collapse;
  overflow-x: scroll;
}

/* Customize scrollbar appearance for WebKit browsers (Chrome, Safari) */
.tableRow::-webkit-scrollbar {
  width: 0 !important;
}

/* Customize scrollbar appearance for WebKit browsers (Chrome, Safari) */
.tableRow::-webkit-scrollbar-thumb {
  display: none !important; /* Hide scrollbar thumb */
}

/* Customize scrollbar appearance for Firefox */
.tableRow {
  scrollbar-width: 0; /* Hide scrollbar in Firefox */
  background: rgba(6, 24, 88, 0.075);
  border-radius: 16px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
  border: #6750ce 1px solid;
  backdrop-filter: blur(7.6px);
  -webkit-backdrop-filter: blur(7.6px);
  padding: 25px 0;
  margin: 20px auto;
}

.productTableHead th {
  padding: 12px 15px;
  text-align: left;
  border: 0;
  position: sticky;
  top: 0;
  background-color: #face1d;
  color: rgba(5, 29, 105, 1);
  cursor: pointer;
  z-index: 2; /* Ensure the header is above the blurred content */
}

.productTableHead th:hover {
  background-color: #fff;
  color: rgb(13, 40, 129);
}

.productTableHead td {
  position: sticky;
  top: 0;
  background-color: #face1d;
  z-index: 1; /* Ensure the header is above the blurred content */
}

.productTableRow td {
  padding: 12px 15px;
  text-align: left;
  border: 0;
  position: relative; /* Make position relative to handle z-index */
}

.scrollable-tbody {
  max-height: 300px; /* Set the max height for the tbody */
  overflow-y: auto;
  position: relative; /* Make position relative */
  z-index: 0; /* Ensure the content is below the header */
  backdrop-filter: blur(7.6px); /* Apply the blur filter to the tbody */
}

.productTableHead:hover .scrollable-tbody {
  overflow-y: scroll; /* Show scrollbar on hover */
}

.table-container {
  max-height: 350px;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.productTableRow {
  border-radius: 16px;
  margin: 10px;
  transition: background-color 0.3s ease;
  border: 0;
  z-index: 0; /* Ensure the content is below the header */
}

.productTableRow:hover {
  background: rgba(5, 28, 105, 0.747);
}

.productTableImage {
  width: 100px;
  height: auto;
}
