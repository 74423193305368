.homeContainer{
	min-height: 100vh;
	/* display: flex; */
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 0.1px solid #acacac;
}

.homeBar1{
    padding: 5vh 0;
	height: 30vh;
}

.homeBar2{
	height: 20vh;
	display: flex;
    flex-direction: row;
	justify-content: center;
	align-items: center;
}

.search-bar {
    max-width: 600px; /* Adjust as needed */
    margin: auto;
    margin-top: 20px;
  }


.homeCard{
    height: 130px;
    width: 200px;
    background: rgba(35, 83, 240, 0.15);
    border-radius: 16px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.6px);
    -webkit-backdrop-filter: blur(7.6px);
    border: 1px solid rgb(7, 18, 168);
    margin: 0 30px;
}




.homeCardT1{
    font-size: 36px;
    color: #face1d;
    text-align: center;
    margin: 0;
}

.homeCardT2{
    font-size: 24px;
    color: #face1d;
    text-align: center;
    margin: 0;
}

.searchField{
    height: 60px;
}

.searchLeft{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.searchRight{
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.registerRow{
    width: 100%;
}

.vyapaarImage{
    width: 140px;
}

@media screen and (max-width: 768px) { 
    .homeBar1{
        padding: 5vh 0;
        height: 20vh;
    }

    .homeBar2{
        height: 60vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .vyapaarImage{
        width: 100px;
    }
    

    
    /* .registerRow{
        position: relative;
        left: 20;
        bottom: 20;
        width: 100%;
    }     */
}