.forgotPass{
    padding-top: 15vh;
    display: flex;
    justify-content: center;
    color: #face1d;
}

.forgotPassFormConatiner{
    width: 30%;
    padding: 20px;
}

.forgotPassBox{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: linear-gradient(153deg, rgba(13, 35, 109, 0.315) 0%, #14044780 100%);
    box-sizing: border-box;
    box-shadow: 0 0px 25px #b0c20a;
    border-radius: 10px;
}


@media screen and (max-width: 960px) {
    .forgotPassBox{
        max-width: 80%;
    }
}