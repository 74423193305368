
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

::-webkit-scrollbar-thumb {
  background:  rgba(0, 0, 0, 0.664); 
  border-radius: 10px;
  display: none;
}

::-webkit-scrollbar-track-piece{
  background-color: rgb(255, 255, 255) !important;
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  background: #fff;
  display: none;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Quattrocento Sans', sans-serif;
}

.App {
  width: 100%;
  cursor: var(--cursor);
  overflow-x: hidden;
  color: #fff;;  
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5;
  color: #face1d;
  background-color: transparent;
  background-clip: padding-box;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  border: 1px solid #face1d;
  transition: none; 
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-family: 'Alegreya', serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 2;
  color: #face1d;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #face1d;
  border-radius: 5px;
  -webkit-appearance: none;
  appearance: none;
  transition: none; 
}

.form-label {
  margin-bottom: 0;
  font-size: 18px;
  margin-top: 15px;
  font-weight: 500;
}

.form-control:focus, .form-control:hover {
  background: transparent;
  color: #face1d;
  outline: 0;
  border-color: #face1d;
  box-shadow: none;
  transition: 300ms;
}

.form-control::placeholder, .form-control[type=date], .form-control[type=time], .form-select[select]{ 
  color: #face1d;
  opacity: 1; 
} 

 .form-control::-ms-input-placeholder{ 
  color: #face1d;
}

.form-control::-ms-input-placeholder{ 
  color: #face1d;
}

option {
  color: #000;
}

.form-select:focus{
  border-color: #face1d;
  background: transparent;
  color: #face1d;
  outline: 0; 
  box-shadow: none;
  transition: 300ms;
}

.btn {
  padding: 8px 20px;
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--primary {
  background-color: #face1d;
  color: rgb(63, 9, 134);
  border: 1px solid rgb(63, 9, 134);
  box-shadow: inset 0 0 0 0 rgb(63, 9, 134);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.btn--secondary {
  background-color: rgb(63, 9, 134);
  color: #face1d;
  padding: 8px 20px;
  border: 1px solid rgb(63, 9, 134);
  transition: all 0.3s ease-out;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}


.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
  cursor: pointer;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
  cursor: pointer;
}

.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #face1d;
  color: rgb(63, 9, 134);
  transition: 500ms;
  cursor: pointer;
}

.btn--primary:hover {
  transition: all 0.4s ease-out;
  box-shadow: inset 300px 0 0 0 rgb(63, 9, 134);
  color: #face1d;
  transition: 1000ms;
}

.btn--secondary:hover {
  transition: all 0.4s ease-out;
  background-color: #face1d;
  color: rgb(63, 9, 134);
  padding: 8px 20px;
  border: 1px solid #face1d;
  transition: all 0.3s ease-out;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}


.btn--outline {
  margin-top: 0.5em;
  background-color: #face1d;
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  color: rgb(63, 9, 134);
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  transition: 0.2s;
  cursor: pointer;
}

.btn--outline:hover{
  background: rgb(63, 9, 134);
  box-shadow: 0 0 10px rgb(63, 9, 134), 0 0 40px rgb(63, 9, 134), 0 0 80px rgb(63, 9, 134);
  transition-delay: 1s;
  color: #face1d;
  cursor: pointer;
}

.btn--outline:focus {
  background: rgb(63, 9, 134);
  box-shadow: none;
  cursor: pointer;
  border: 0;
}


.btn--outline span {
  position: absolute;
  display: block;
}

.btn--outline span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgb(63, 9, 134));
}

.btn--outline:hover span:nth-child(1) {
  left: 100%;
  transition: 0.5s;
}

.btn--outline span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, rgb(63, 9, 134));
}

.btn--outline:hover span:nth-child(3) {
  right: 100%;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.btn--outline span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, rgb(7,10,78));
}

.btn--outline:hover span:nth-child(2) {
  top: 100%;
  transition: 0.5s;
  transition-delay: 0.25s;
}

.btn--outline span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, rgb(7,10,78));
}

.btn--outline:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.5s;
  transition-delay: 0.75s;
}

.btn--outline--dark {
  margin-top: 0.5em;
  background-color: rgb(63, 9, 134);
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  color: #face1d;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  transition: 0.2s;
  cursor: pointer;
}

.btn--outline--dark:hover{
  background: #face1d;
  box-shadow: 0 0 10px #face1d, 0 0 40px #face1d, 0 0 80px #face1d;
  transition-delay: 1s;
  color: rgb(7,10,78);
  cursor: pointer;
}

.btn--outline--dark:focus {
  background: #fff;
  color: rgb(7,10,78);
  box-shadow: none;
  cursor: pointer;
  border: 0;
}


.btn--outline--dark span {
  position: absolute;
  display: block;
}

.btn--outline--dark span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #face1d);
}

.btn--outline--dark:hover span:nth-child(1) {
  left: 100%;
  transition: 0.5s;
}

.btn--outline--dark span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #face1d);
}

.btn--outline--dark:hover span:nth-child(3) {
  right: 100%;
  transition: 0.5s;
  transition-delay: 0.5s;
}

.btn--outline--dark span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #face1d);
}

.btn--outline--dark:hover span:nth-child(2) {
  top: 100%;
  transition: 0.5s;
  transition-delay: 0.25s;
}

.btn--outline--dark span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #face1d);
}

.btn--outline--dark:hover span:nth-child(4) {
  bottom: 100%;
  transition: 0.5s;
  transition-delay: 0.75s;
}

.btn--three {
  background-color: rgb(63, 9, 134);
  color: #face1d;
  padding: 1em 2.5em;
  border: 1px solid rgb(63, 9, 134);
  transition: all 0.5s ease-out;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.btn--three:hover {
  background-color: #face1d;
  color: rgb(63, 9, 134);
  border: 1px solid #face1d;
  cursor: pointer;
  border: 1px solid rgb(63, 9, 134);
}

.btn--four {
  background-color: #face1d;
  color: rgb(63, 9, 134);
  border: 1px solid rgb(63, 9, 134);
  box-shadow: inset 0 0 0 0 rgb(63, 9, 134);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.btn--four:hover{
  background-color: rgb(63, 9, 134);
  color: #face1d;
  transition: 400ms;
}