
.has-error .form-control {
  border-bottom: 1px solid red;
}

.has-error .help-block {
  color:red; 
  margin-bottom: 5px;}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: linear-gradient(153deg, rgba(13, 35, 109, 0.315) 0%, #14044780 100%);
  box-sizing: border-box;
  box-shadow: 0 0px 25px rgb(194, 207, 5);
  border-radius: 10px;
}

.login-box h2 {
  padding: 0;
  color: #face1d;
  text-align: center;
}

  
.login-box .user-box {
  position: relative;
}

.customLabel {
  color: #face1d;
  pointer-events: none;
  transition: .5s;
  margin-bottom: 5px;
  font-size: 24px;
  margin-top: 15px;
  font-weight: 400;

}

.customInput {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #face1d;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border: 2px solid #face1d;
  transition: none;
  margin-bottom: 1.5em;
}

.customInput::placeholder{ 
  color: rgb(184, 223, 134);
  opacity: 1; 
}

.user-box:focus{
  border: none;
}

.customInput:focus, .customInput:hover {
  background: transparent;
  color: #face1d;
  /* font-size: 18px; */
  outline: 0;
  border-color: rgba(246,255,35,1);
  box-shadow: none;
  transition: 200ms;
}

.signUpLinks{
margin: 0;
text-align: right;
color: #face1d;
text-decoration: none !important;
}

.signUpLinks:hover{
text-decoration: underline;
transition: 200ms;
color: rgba(246,255,35,1);
cursor: pointer;
}

.loginTag{
text-align: center;
color: rgba(246,255,35,1);
animation: blinkAnimation 2s infinite;
}

@keyframes blinkAnimation {
0%, 100% {
  opacity: 1;
}
50% {
  opacity: 0.2;
}
}

@media screen and (max-width: 768px) { 
.login-box {
  margin-top: 85px;
  width: 300px;
  padding: 40px 20px;
}


.customLabel {
  color: #face1d;
  pointer-events: none;
  transition: .5s;
  margin-bottom: 5px;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 400;

}

.customInput {
  display: block;
  width: 100%;
  font-size: 15px;
  line-height: 1;
} 

.customInput:focus {
  font-size: 17px;
}


}