  .companyInfoCard{
    height: 250px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.6px);
    -webkit-backdrop-filter: blur(7.6px);
    border: 1px solid #face1d;
    color: #fff;
  }
  
  .companyInfoImage{
    border-radius: 15px;
    margin-top: 20px;
    width: 160px;
  }
  
  .companyInfoText1{
    margin-bottom: 6px;
    font-size: 18px;
  }