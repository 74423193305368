.navbarBg {
  padding-bottom: 10px;
  /* background: rgb(63, 9, 134)5b !important; */
  background: transparent !important;
  margin-bottom: 0px;   
  width: 100%;
  height: 14vh;
  /* backdrop-filter: blur(4px); */
  z-index: 1030;
  position: absolute;
}


.navbar-nav .nav-link {
  color: #face1d;
}

.brandImage{
  position: relative;
  z-index: 10;
  height: 100px;
}

.navbar-nav .nav-link {
  color: #face1d;
  padding: 10px 35px;
  margin: 0 10px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  border-bottom: transparent 2px solid;
  font-weight: 600;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #face1d;
}

.navbar-nav:hover .nav-link:hover{
  color: #face1d;
  border-bottom: #face1d 2px solid;
  transition: 800ms;
} 

.navbar-expand-lg .navbar-nav .dropdown-menu {
  background: rgb(63, 9, 134) !important;
  color: #face1d;
  border: 1px solid #face1d;
  margin: 0;
  padding: 0;
}

.dropdown-toggle{
  border: 1px solid #face1d !important;
  border-radius: 100%; 
}

.dropdown-toggle:hover{
  background-color: #face1d;
  transition: 400ms !important;
  color: #000 !important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #face1d;
  text-decoration: none;
  background: rgb(63, 9, 134) !important;
}

.dropdown-item, .dropdown-item:hover{
  background: #face1d;
  cursor: pointer;
  transition: 300ms;
}

.dropdown-divider {
  border-top: 1px solid #face1d; 
  margin: 0;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin: 0;
  font-size: 16px;
  color: #face1d;
  font-weight: 500;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
}

.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-decoration: none;
  color: #face1d;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-menu.show {
  position: static;
}

@media screen and (max-width: 987px) {
  .navbarBg {
    padding-bottom: 10px;
    background: rgb(63, 9, 134) !important;
    margin-bottom: 0px;   
    min-height: 70px;
    width: 100%;
    }

  .navbar>.container {
      background: rgb(63, 9, 134) !important;
      margin: 0;
      width: 100%;
      margin: 0;
    }

  .customDropdown1{
    width: 90%;
    border-bottom: 0;
    margin: 0 !important;
  }
  
  .customDropdown2{
    width: 90%;
  }
  
  
  .customDropdownItem1 {
    border-right: 0;
  }

  .navbar>.container{
    background: rgb(63, 9, 134) !important;
    margin: 0;
    width: 100%;
  }

  .dropdown-toggle{
    border: 1px solid #face1d !important;
    border-radius: 0 !important; 
  }
  
  .dropdown-toggle:hover{
    background-color: #face1d;
    transition: 400ms !important;
    color: #000 !important;
  }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #face1d;
    text-decoration: none;
    background: rgb(63, 9, 134) !important;
  }


  .brandImage{
    color: #face1d;
    height: 60px;
    z-index: 10;
}

  .navbar-dark .navbar-nav .nav-link {
    text-align: center;
    color: #face1d;
    padding: 30px 20px;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    background: rgb(63, 9, 134) !important;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    color: #face1d;
    align-items: center;
    width: 100%;
  }

}

/* 
@media screen and (max-width: 960px) {
  .navbarBg {
    padding-bottom: 10px;
    min-height: 70px;
    }


  .brandImage{
    width: 60px;
  }

  .navbar-dark .navbar-nav .nav-link {
    text-align: center;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    align-items: center;
  }

  .dropdown-toggle{
    border: 0 !important;
    border-radius: 100%; 
  }
  

} */