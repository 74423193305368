.register{
    width: 100%;
    /* padding-top: 25vh; */
    padding-bottom: 10vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.formContainer{
    width: 60%;
    background-color: rgba(13, 35, 109, 0.315);
    border-radius: 30px;
    border: #630ac2 1px solid;
    padding: 30px;
    backdrop-filter: blur(1px);
    z-index: 1;
    color: #face1d;
}


@media screen and (max-width: 960px) {

.eeFormContainer{
    width: 90%;
    padding: 20px;
    z-index: 1;
}
}